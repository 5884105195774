import type { VacanciesUpdateRequestDto } from "~/entities/vacancy/VacanciesUpdateRequestDto";

export const useApiVacancies = () => {
    return {
        create: (data: VacanciesUpdateRequestDto, isDraft: boolean = false) => {
            return mainFetch('vacancies', {
                method: 'POST',
                body: {
                    isDraft,
                    ...data,
                },
            });
        },
        /**
         * Получить список вакансий
         * @param company
         * @param page
         */
        get: (company: string, type: string = 'search', page: number = 1) => {
            return mainFetch('vacancies', {
                query: { company, type, page }
            });
        },
        update: (id: string, data: VacanciesUpdateRequestDto) => {
            return mainFetch(`vacancies/${id}`, {
                method: 'PUT',
                body: data
            });
        },
        /**
         * Переместить или извлеч вакансию из архива
         * @param id ID вакансии
         * @param toArchive Если `true`, вакансия помещается в архив, иначе публикуется
         * @param isRestoreResponses Если `undefined`, отклики остаются в соих статусах, если `true`, отклики восстанавливаются, иначе перемещаются в отказ
         */
        archiving: (id: string, toArchive: boolean = true, isRestoreResponses: undefined|boolean = undefined) => {
            return mainFetch(`/vacancies/${id}`, {
                method: 'PUT',
                body: {
                    status: toArchive ? 1 : 2,
                    isRestoreResponses
                }
            });
        }
    };
}
